import React from 'react';
import {Panel, PanelList, Tab, TabList, Tabs} from '@react-tabtab-next/tabtab';
import tabStyle from './style/tab'
import {MGuide} from './guide';
import {MHistory} from './history';
import request from '../utils/request';

class MHome extends React.PureComponent{
  state = {
    machine: {},
    potChangeRequests: [],
    activeIndex: 0,
  };
  
  async componentDidMount() {
    const params = window.location.pathname.substr(1).split('/');
    if (params.length >= 4) {
      localStorage.setItem('vidNumber', params[1]);
      localStorage.setItem('cookCount', params[2]);
      localStorage.setItem('accessToken', params[3]);
    }
    this.loadData();
  }
  
  async loadData() {
    const response1 = await request(`/as/hg/open/machine/${localStorage.getItem('vidNumber')}?cookCount=${localStorage.getItem('cookCount')}`);
    if (response1.constructor.name !== 'Error') {
      this.setState({
        machine: response1
      });
    } else {
      this.setState({
        machine: {
          machineId: '-1',
        }
      });
      response1.machineId = '-1';
    }
    const response2 = await request(`/as/hg/open/potChangeRequests/findAll?vidNumber=${localStorage.getItem('vidNumber')}&bizMachineId=${response1.machineId}`);
    if (response2.constructor.name !== 'Error') {
      this.setState({
        potChangeRequests: response2._embedded.bizPotChangeRequests,
      });
    } else {
      this.setState({
        potChangeRequests: [],
      })
    }
  }
  
  onRequestSubmitted = () => {
    this.loadData().then(() => {
      this.setState({
        activeIndex: 1
      })
    });
  };
  
  onRequestModified = () => {
    this.loadData();
  };
  
  onDeleteSubmitted = () => {
    this.loadData();
  };
  
  render() {
    const { machine, potChangeRequests, activeIndex } = this.state;
    return (
      <Tabs
        customStyle={tabStyle}
        showArrowButton={false}
        activeIndex={activeIndex}
        onTabChange={index => {
          this.setState({
            activeIndex: index
          })
        }}
      >
        <TabList>
          <Tab>换锅申请</Tab>
          <Tab>换锅记录</Tab>
        </TabList>
        <PanelList>
          <Panel>
            {machine.machineId &&
              <MGuide
                machine={machine}
                potChangeRequests={potChangeRequests}
                onRequestSubmitted={this.onRequestSubmitted}
                onRequestModified={this.onRequestModified}
              />
            }
          </Panel>
          <Panel>
            {machine.machineId &&
              <MHistory
                machine={machine}
                potChangeRequests={potChangeRequests}
                onDeleteSubmitted={this.onDeleteSubmitted}
              />
            }
          </Panel>
        </PanelList>
      </Tabs>
    );
  }
}

export {MHome};
