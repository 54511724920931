/* eslint-disable no-undef */
import fetch from 'isomorphic-fetch';
import {toast} from 'react-toastify';
import {stringify} from 'qs';
import * as CryptoJS from 'crypto-js';
import {randomString} from './utils';

const sk = CryptoJS.enc.Utf8.parse('tQ7jsHLFs3e0sFl1');
const iv = CryptoJS.enc.Utf8.parse('7cDjV97vMuQpfsFZ');
const vk = CryptoJS.enc.Utf8.parse('changshan' + randomString(16));
const sec = CryptoJS.AES.encrypt(vk, sk, {
  iv: iv,
  mode: CryptoJS.mode.CBC,
  padding: CryptoJS.pad.Pkcs7,
}).toString();

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
export default function request(url, options) {
  if (!url.includes('https://') && !url.includes('http://')) {
    url = `${process.env.REACT_APP_API_HOST}${url}`;
  }

  const defaultOptions = {
    credentials: 'include',
    headers: {
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : ''}.${sec}`,
      refreshToken: localStorage.getItem('refreshToken'),
    },
    /* cache: 'no-cache' */
  };
  const newOptions = { ...defaultOptions, ...options };
  const commonJsonMethod = new Set(['POST', 'PUT', 'PATCH', 'DELETE']);

  if (newOptions.isFormBody) {
    if (newOptions.method === 'GET' || newOptions.method === 'POST') {
      //fetch不支持GET请求带body，改成POST
      newOptions.method = 'POST';
      newOptions.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        ...newOptions.headers,
      };
      newOptions.body = stringify(newOptions.body);
    }
  }
  else if (newOptions.isFilesBody) {
    // eslint-disable-next-line
  }
  else if (commonJsonMethod.has(newOptions.method)) {
    newOptions.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      ...newOptions.headers,
    };
    newOptions.body = JSON.stringify(newOptions.body);
  }

  // noinspection JSUnresolvedFunction
  return fetch(url, newOptions)
    .then((response) => {
      const isJson = response.headers.get('content-type') && response.headers.get('content-type')
        .includes('json');
      const isSuccess = response.status >= 200 && response.status < 300;

      if (isSuccess) {
        /*let accessToken = response.headers.get('accessToken');

        const refreshToken = response.headers.get('refreshToken');
        if (accessToken) {
          const split = accessToken.split('.');
          if (split.length > 3) {
            accessToken = `${split[0]}.${split[1]}.${split[2]}`
          }
          localStorage.setItem('accessToken', accessToken);
          localStorage.setItem('refreshToken', refreshToken);
        }*/
        if (isJson) {
          return response.json();
        }
        else {
          return response.text();
        }
      }
      if (!isSuccess) {
        if (isJson) {
          return response.json()
            .then((errorInfo) => {
              if (errorInfo.statusCode !== 1003 && errorInfo.statusCode !== 1006) {
                const error = new Error(errorInfo.message);
                error.name = `请求出错 ${errorInfo.statusCode}`;
                error.response = response;
                throw error;
              }
              else
                return errorInfo;
            });
        }
        else {
          return response.text()
            .then(text => {
              const error = new Error(text);
              error.name = `请求出错 ${response.status}`;
              error.response = response;
              throw error;
            });
        }
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        toast.dismiss();
        toast.error(error.message);

        //过期(code:1007)跳转到登录 其他401权限只弹出错误
        // if (error.name.includes('1007')) {
        //history.push('/user/login');
        // }
        //return Promise.reject(error);
        return error;
      }

      if (error.code) {
        toast.dismiss();
        toast.error(error.message);
      }
      if ('stack' in error && 'message' in error) {
        toast.dismiss();
        toast.error(error.message === 'Failed to fetch' ? '无法访问服务器，请稍后再试' : error.message);
        return new Error(error.message);
      }
      return error;
    });
}
