import React from 'react';
import moment from 'moment';
import styles from './utils.less';

export function getWindowWidth() {
  return (window.innerWidth || document.documentElement.clientWidth);
}

//复制到剪切板
export function copyToClipboard(s) {
  if (window.clipboardData) {
    window.clipboardData.setData('text', s);
  } else {
    (function(s) {
      document.oncopy = function(e) {
        e.clipboardData.setData('text', s);
        e.preventDefault();
        document.oncopy = null;
      }
    })(s);
    document.execCommand('Copy');
  }
}

export function randomString(len, charSet) {
  charSet = charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';
  for (let i = 0; i < len; i++) {
    let randomPoz = Math.floor(Math.random() * charSet.length);
    randomString += charSet.substring(randomPoz,randomPoz+1);
  }
  return randomString;
}

export function transformString(s) {
  //大小写转换
  //倒序
  //字符移位
  //左右镜像

}

export function fixedZero(val) {
  return val * 1 < 10 ? `0${val}` : val;
}

export function getTimeDistance(type) {
  const now = new Date();
  const oneDay = 1000 * 60 * 60 * 24;

  if (type === 'today') {
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return [moment(now), moment(now.getTime() + (oneDay - 1000))];
  }

  if (type === 'week') {
    let day = now.getDay();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    if (day === 0) {
      day = 6;
    } else {
      day -= 1;
    }

    const beginTime = now.getTime() - (day * oneDay);

    return [moment(beginTime), moment(beginTime + ((7 * oneDay) - 1000))];
  }

  if (type === 'month') {
    const year = now.getFullYear();
    const month = now.getMonth();
    const nextDate = moment(now).add(1, 'months');
    const nextYear = nextDate.year();
    const nextMonth = nextDate.month();

    return [moment(`${year}-${fixedZero(month + 1)}-01 00:00:00`),
      moment(moment(`${nextYear}-${fixedZero(nextMonth + 1)}-01 00:00:00`).valueOf() - 1000)];
  }

  if (type === 'year') {
    const year = now.getFullYear();

    return [moment(`${year}-01-01 00:00:00`), moment(`${year}-12-31 23:59:59`)];
  }
}

export function getPlainNode(nodeList, parentPath = '') {
  const arr = [];
  nodeList.forEach((node) => {
    const item = node;
    item.path = `${parentPath}/${item.path || ''}`.replace(/\/+/g, '/');
    item.exact = true;
    if (item.children && !item.component) {
      arr.push(...getPlainNode(item.children, item.path));
    } else {
      if (item.children && item.component) {
        item.exact = false;
      }
      arr.push(item);
    }
  });
  return arr;
}

export function digitUppercase(n) {
  const fraction = ['角', '分'];
  const digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
  const unit = [
    ['元', '万', '亿'],
    ['', '拾', '佰', '仟'],
  ];
  let num = Math.abs(n);
  let s = '';
  fraction.forEach((item, index) => {
    s += (digit[Math.floor(num * 10 * (10 ** index)) % 10] + item).replace(/零./, '');
  });
  s = s || '整';
  num = Math.floor(num);
  for (let i = 0; i < unit[0].length && num > 0; i += 1) {
    let p = '';
    for (let j = 0; j < unit[1].length && num > 0; j += 1) {
      p = digit[num % 10] + unit[1][j] + p;
      num = Math.floor(num / 10);
    }
    s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
  }

  return s.replace(/(零.)*零元/, '元').replace(/(零.)+/g, '零').replace(/^整$/, '零元整');
}

//对查询关键字显示高亮
export function getHighlightText(list, searchText) {
  const reg = new RegExp(searchText, 'gi');
  return list.map((record) => {
    const match = record.name.match(reg);
    if (!match) {
      return null;
    }
    // const newStr = record.name.replace(reg, `<span className={styles.highlight}>${searchText}</span>`);
    return {
      ...record,
      highLightName: (
        <span>
          {record.name.split(new RegExp(`(${searchText})`, 'i')).map((text, i) => (
            // record.name.split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')).map((text, i) => (
            text.toLowerCase() === searchText.toLowerCase()
              ? <span key={i} className={styles.highlight}>{text}</span>
              : text // eslint-disable-line
          ))}
          {/*{*/}
          {/*newStr*/}
          {/*}*/}
        </span>
      ),
    };
  }).filter(record => !!record);
}

/*
   * obj是一个对象，其中包含有：
   * ## data 是导出的具体数据
   * ## tableTitle 表格上方标题 是string格式
   * ## showLabel 表示是否显示表头 默认显示 是布尔格式
   * ## columns 是表头对象，且title和key必须一一对应，包含有
        title:[], // 表头展示的文字
        key:[], // 获取数据的Key
        formatter: function() // 自定义设置当前数据的 传入(key, value)
   * ## csv 已存在的文件，不能一次性生成csv可多次调用将数据添加到同一个csv
   */
export function jsonToCSV(obj, csv) {
  const { data } = obj;
  // const fileName = `${(obj.fileName ? obj.fileName : '菜谱详情')}.csv`;
  const tableTitle = obj.tableTitle ? obj.tableTitle : '';
  const showLabel = obj.showLabel === false ? obj.showLabel : true;
  const columns = obj.columns ? obj.columns : { title: [], key: [], formatter: undefined };
  let row = '';
  let CSV = !csv ? '' : csv;

  //如果table有标题介绍先插入标题
  if (tableTitle)
    CSV += `${tableTitle}\r\n`;
  // 如果要现实表头文字
  if (showLabel) {
    // 如果有传入自定义的表头文字
    if (columns.title.length) {
      columns.title.forEach(item => {
        row += `${item},`;
      });
    } else {
      // 如果没有，就直接取数据第一条的对象的属性
      Object.keys(data[0]).forEach(key => {
        row += `${key},`;
      });
    }
    row = row.slice(0, -1); // 删除最后一个,号，即a,b, => a,b
    CSV += `${row}\r\n`; // 添加换行符号
  }
  // 具体的数据处理
  data.forEach((dataItem) => {
    row = '';
    let keys = Object.keys(dataItem);
    // 如果存在自定义key值
    if (columns.key.length) {
      keys = columns.key;
    }
    keys.forEach(keyItem => {
      row += `"${typeof columns.formatter === 'function' ?
        columns.formatter(keyItem, dataItem[keyItem]) || dataItem[keyItem] : dataItem[keyItem]}",`;
    });

    row.slice(0, row.length - 1); // 删除最后一个,
    CSV += `${row}\r\n`; // 添加换行符号
  });
  if (!CSV) return null;
  CSV += '\r\n\r\n';
  return CSV;
  // saveAs(fileName, CSV);
}
//下载
export function saveAs(fileName, csvData) {
  // console.log(fileName, csvData);
  //获取浏览器类型
  const browser = () => {
    const Sys = {};
    const ua = navigator.userAgent.toLowerCase();
    let s;
    (s = ua.indexOf('edge') !== -1 ? Sys.edge = 'edge' : ua.match(/rv:([\d.]+)\) like gecko/)) ? Sys.ie = s[1] :// eslint-disable-line
      (s = ua.match(/msie ([\d.]+)/)) ? Sys.ie = s[1] :
        (s = ua.match(/firefox\/([\d.]+)/)) ? Sys.firefox = s[1] :
          (s = ua.match(/chrome\/([\d.]+)/)) ? Sys.chrome = s[1] :
            (s = ua.match(/opera.([\d.]+)/)) ? Sys.opera = s[1] :
              (s = ua.match(/version\/([\d.]+).*safari/)) ? Sys.safari = s[1] : 0;

    return Sys;
  };
  //获取下载链接
  const getDownloadUrl = () => {
    const _utf = '\uFEFF'; // 为了使Excel以utf-8的编码模式，同时也是解决中文乱码的问题
    if (window.Blob && window.URL && window.URL.createObjectURL) {
      const newCsvData = new Blob([_utf + csvData], {
        type: 'text/csv',
      });
      return URL.createObjectURL(newCsvData);
    }
    // return 'data:attachment/csv;charset=utf-8,' + _utf + encodeURIComponent(csvData);
  };

  const bw = browser();
  if (!bw.edge || !bw.ie) {
    const alink = document.createElement('a');
    alink.id = 'linkDwnldLink';
    alink.href = getDownloadUrl();
    document.body.appendChild(alink);
    const linkDom = document.getElementById('linkDwnldLink');
    linkDom.setAttribute('download', fileName);
    linkDom.click();
    document.body.removeChild(linkDom);
  }
  else if (bw.ie >= 10 || bw.edge === 'edge') {
    const _utf = '\uFEFF';
    const _csvData = new Blob([_utf + csvData], {
      type: 'text/csv',
    });
    navigator.msSaveBlob(_csvData, fileName);
  }
  else {
    const oWin = window.top.open('about:blank', '_blank');
    oWin.document.write(`sep=,\r\n${csvData}`);
    oWin.document.close();
    oWin.document.execCommand('SaveAs', true, fileName);
    oWin.close();
  }
}

/*将指定长度字符串按指定长度进行剪切
  <param name="oldStr">需要截断的字符串</param>
  <param name="maxLength">字符串的最大长度</param>
  <param name="endWith">若超过长度，返回截断后的新字符加上后缀，否则，返回原字符串</param>
*/
export function stringTruncat(oldStr, maxLength, endWith) {
  if (!oldStr || maxLength < 1)
    return '';
  if (oldStr.length > maxLength) {
    const strTmp = oldStr.substr(0, maxLength);
    if (!endWith)
      return strTmp;
    else
      return strTmp + endWith;
  }
  return oldStr;
}
