import React from 'react';
import {BrowserRouter, Route} from 'react-router-dom';
import style_m from './app_m.module.scss'
import 'react-toastify/dist/ReactToastify.css';

import {MHome} from './mobile/home';
//import {MGuide} from './mobile/guide';
//import {MHistory} from './mobile/history';
import {ToastContainer} from 'react-toastify';

class MobileLayout extends React.PureComponent {
  state = {
  };
  
  componentDidMount() {
  }
  
  toggleMenu = e => {
    e.stopPropagation();
  };
  
  render() {
    return (
      <div>
        <main className={style_m.main}>
          {/*<Route path='/' component={MHome}/>*/}
          <Route path='/home' component={MHome}/>
          <Route path='/guide' component={MHome}/>
          {/*<Route path='/guide' component={MGuide}/>*/}
          {/*<Route path='/history' component={MHistory}/>*/}
        </main>
        <ToastContainer style={{ fontSize: '16px' }} autoClose={1500} hideProgressBar={true} />
      </div>
    );
  }
}

const App = () => {
  return (
    <BrowserRouter>
      <MobileLayout/>
    </BrowserRouter>
  );
};

export default App;
