import styled from 'styled-components';
import { styled as themeStyled } from '@react-tabtab-next/tabtab';

let { TabList, ActionButton, Tab, Panel  } = themeStyled;

TabList = styled(TabList)`
    box-shadow: inset 0 -8px 8px -8px gray;
`;

Tab = styled(Tab)`
    flex: 1;
    text-align: center;
    margin: 0.3rem 0.5rem 0.3rem 0.5rem;
    font-size: 0.5rem;
    background: transparent;
    ${(props) => {
  return props.active && !props.vertical
    ? `
      font-weight: bold;
      border-bottom: 1px solid orange;
    `
    : `
      color: gray;
    `;
}}
`;

ActionButton = styled(ActionButton)`
    background-color: transparent;
    border-radius: 0;
    border: none;
    opacity: 0.3;
    transition: opacity 0.2s;
    & svg {
        font-size: 21px;
        padding: 0;
    }
    &:hover {
        opacity: 1;
    }
`;

Panel = styled(Panel)`
    padding: 0
`;

// need to follow this object naming
export default { TabList, ActionButton, Tab, Panel };
