import React, {Fragment} from 'react';
import s from './style/history.module.scss'
import request from '../utils/request';
import moment from 'moment/moment';
import {toast} from 'react-toastify';
import LoadingMask from '../components/loadingmask';
import '../components/loadingmask/index.css'

import imgDelete from '../assets/mobile/delete.png';
import imgCopy from '../assets/mobile/copy.png';
import {copyToClipboard} from '../utils/utils';
import consts from '../utils/consts'

class MHistory extends React.PureComponent{
  state = {
    machine: this.props.machine,
    submitting: false,
  };
  
  async handleDelete(id, e) {
    e.preventDefault();
    e.stopPropagation();
    const result = window.confirm('确认要删除吗？');
    if (result === true) {
      this.setState({submitting: true});
      const response = await request(`/as/hg/open/potChangeRequests/delete?id=${id}`, {
        method: 'DELETE',
      });
      if (response.constructor.name !== 'Error') {
        toast.success("删除成功");
        if (this.props.onDeleteSubmitted) {
          this.props.onDeleteSubmitted();
        }
      }
      this.setState({submitting: false});
    }
  }
  
  handleCopyClick = (str, e) => {
    e.preventDefault();
    e.stopPropagation();
    copyToClipboard(str);
    alert('复制成功');
  };
  
  render() {
    const { potChangeRequests } = this.props;
    const { submitting } = this.state;
    const status = [
      {
        name: '申请已提交',
        color: 'darkorange',
      },
      {
        name: '旧锅已发出',
        color: 'darkorange',
      },
      {
        name: '旧锅已收到',
        color: 'darkseagreen',
      },
      {
        name: '仓库已确认',
        color: 'darkseagreen',
      },
      {
        name: '已推单',
        color: 'darkseagreen',
      },
      {
        name: '新锅待发出',
        color: 'darkgreen',
      },
      {
        name: '新锅已发出',
        color: 'darkgreen',
      },
    ];
    return (
      <LoadingMask loading={submitting}>
        <div className={s.container}>
          <div className={s.block1}>
            {potChangeRequests.map(item => {
              let statusValue = item.status;
              //3=仓库已确认, 4=已推单, 5=新锅待发出
              //为内部状态，转为2=旧锅已收到
              if (statusValue === consts.potChangeRequestStatus.StockConfirmed
                || statusValue === consts.potChangeRequestStatus.OrderPushed
                || statusValue === consts.potChangeRequestStatus.NewPotWaitForDeliver
              ) {
                statusValue = consts.potChangeRequestStatus.OldPotReceived;
              }
              return (
                <Fragment key={item._id}>
                  <div className={s.block11}>
                    <div className={s.block111}>
                      <div className={s.block111_time}>
                        提交日期：{moment(item.requestTime).format('YYYY-MM-DD HH:mm:ss')}
                      </div>
                      <div className={s.block111_no}>
                    <span>
                      旧锅快递单号：{item.oldPotDeliverNo !== '' ? <a href={`https://page.cainiao.com/guoguo/app-myexpress-taobao/express-detail.html?mailNo=${item.oldPotDeliverNo}`} target="_blank">{item.oldPotDeliverNo}</a> : '无'}
                    </span>
                        {item.oldPotDeliverNo !== '' && <img src={imgCopy} alt="" onClick={this.handleCopyClick.bind(this, item.oldPotDeliverNo)} />}
                      </div>
                      <div className={s.block111_no}>
                    <span>
                      新锅快递单号：{item.newPotDeliverNo !== '' ? <a href={`https://page.cainiao.com/guoguo/app-myexpress-taobao/express-detail.html?mailNo=${item.newPotDeliverNo}`} target="_blank">{item.newPotDeliverNo}</a> : '无'}
                    </span>
                        {item.newPotDeliverNo !== '' && <img src={imgCopy} alt="" onClick={this.handleCopyClick.bind(this, item.newPotDeliverNo)} />}
                      </div>
                      <div className={s.block111_status}>
                        状态：<span style={{color: status[statusValue].color}}>{status[statusValue].name}</span>
                      </div>
                    </div>
                    {statusValue === 0 &&
                    <div className={s.block112} onClick={this.handleDelete.bind(this, item._id)}>
                      <img src={imgDelete} alt=""/>
                      <span>删除</span>
                    </div>
                    }
                  </div>
                  <hr/>
                </Fragment>
              );
            })}
          </div>
      
        </div>
      </LoadingMask>
    );
  }
}

export {MHistory};
